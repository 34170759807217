import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';
import './Auth.scss';

export function Auth() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isBetaSignup, setIsBetaSignup] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const { signIn, signUp } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/app`
        }
      });
      
      if (error) {
        setError(error.message);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleBetaSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    try {
      const { error } = await supabase
        .from('beta_requests')
        .insert([{ email, name }]);

      if (error) {
        if (error.code === '23505') { // unique violation
          setError('This email has already requested beta access.');
        } else {
          setError(error.message);
        }
      } else {
        setMessage('Thank you for your interest! We\'ll review your request and contact you soon.');
        setEmail('');
        setName('');
        setIsBetaSignup(false);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    if (isSignUp) {
      const { error, data } = await signUp(email, password);
      console.log('Signup result:', { error, data });
      
      if (error) {
        if (error.message.includes('already registered')) {
          setError(
            'This email is already registered. If you signed up with Google, please use the "Sign in with Google" button.'
          );
        } else {
          setError(error.message);
        }
      } else if (data?.user?.identities?.length === 0) {
        setError(
          'This email is already registered. If you signed up with Google, please use the "Sign in with Google" button.'
        );
      } else {
        setMessage(
          'Please check your email for a verification link before signing in. ' +
          'If you don\'t see the email, please check your spam folder.'
        );
        setIsSignUp(false);
      }
    } else {
      const { error } = await signIn(email, password);
      if (error) {
        if (error.message.includes('Email not confirmed')) {
          setError(
            'Please verify your email address before signing in. ' +
            'Click "Resend Verification Email" below if you need a new verification email.'
          );
        } else if (error.message.includes('Invalid login credentials')) {
          setError(
            'Invalid email or password. If you signed up with Google, please use the "Sign in with Google" button.'
          );
        } else {
          setError(error.message);
        }
      } else {
        navigate('/app');
      }
    }
  };

  if (isBetaSignup) {
    return (
      <div className="auth-container">
        <div className="auth-box">
          <h2>Request Beta Access</h2>
          {message && <div className="success-message">{message}</div>}
          
          <div className="form-container">
            <form onSubmit={handleBetaSignup}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit" className="auth-button">
                Request Access
              </button>
            </form>
          </div>

          <p className="auth-toggle">
            Already have access?{' '}
            <button onClick={() => {
              setIsBetaSignup(false);
              setError(null);
              setMessage(null);
            }}>
              Sign In
            </button>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
        {message && <div className="success-message">{message}</div>}
        
        <button onClick={handleGoogleSignIn} className="google-button">
          <img src="https://www.google.com/favicon.ico" alt="Google" className="google-icon" />
          Sign in with Google
        </button>

        <div className="divider">
          <span>or</span>
        </div>

        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="username"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
              />
            </div>
            {error && <div className="error-message">{error}</div>}
            <button type="submit" className="auth-button">
              {isSignUp ? 'Sign Up' : 'Sign In'} with Email
            </button>
          </form>
        </div>

        <p className="auth-toggle">
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
          <button onClick={() => {
            setIsSignUp(!isSignUp);
            setError(null);
            setMessage(null);
          }}>
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </button>
        </p>

        <p className="auth-toggle">
          Want to join the beta?{' '}
          <button onClick={() => {
            setIsBetaSignup(true);
            setError(null);
            setMessage(null);
          }}>
            Request Access
          </button>
        </p>
      </div>
    </div>
  );
} 