import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, isWhitelisted, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="auth-container">
        <div className="auth-box">
          <h2>Loading...</h2>
        </div>
      </div>
    );
  }

  if (!user || !isWhitelisted) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
} 