import { useNavigate } from 'react-router-dom';
import './LandingPage.scss';

export const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <nav className="landing-nav">
        <div className="nav-brand">🦜 callpauli.ai</div>
        <div className="nav-links">
          <a href="#" className="nav-link">How It Works</a>
          <a href="#" className="nav-link">FAQ</a>
          <button 
            onClick={() => navigate('/auth')} 
            className="sign-in-btn"
          >
            Sign in
          </button>
        </div>
      </nav>

      <main className="hero-section">
        <div className="hero-content">
          <h1>Tech Support for Life</h1>
          <p>
            Call Pauli — your AI tech support agent that can see your screen, autonomously search the internet, and fix any technical issue you may have. Sign up for the beta now.
          </p>
          <button 
            onClick={() => navigate('/auth')} 
            className="join-beta-btn"
          >
            Join the Beta
          </button>
          <div className="example-queries">
            <div className="query-item">✓ "How do I connect my printer to WiFi?"</div>
            <div className="query-item">✓ "How do I attach a .pdf to an email?"</div>
            <div className="query-item">✓ "How do I transfer photos from my phone?"</div>
            <div className="query-item">✓ "I can't hear sound on my video calls."</div>
            <div className="query-item">✓ "Why is my internet connection so slow?"</div>
          </div>
        </div>
        <div className="hero-image">
          <img src="/hero_parrot.png" alt="AI Assistant Parrot" />
        </div>
      </main>
    </div>
  );
}; 