import React, { createContext, useContext, useEffect, useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';

type AuthContextType = {
  session: Session | null;
  user: User | null;
  signIn: (email: string, password: string) => Promise<{ error: any }>;
  signUp: (email: string, password: string) => Promise<{ error: any; data: any }>;
  signOut: () => Promise<void>;
  isWhitelisted: boolean;
  isLoading: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
      checkWhitelist(session?.user?.email);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
      await checkWhitelist(session?.user?.email);
      
      // Navigate based on auth state
      if (session && _event === 'SIGNED_IN') {
        navigate('/app');
      } else if (_event === 'SIGNED_OUT') {
        navigate('/');
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  const checkWhitelist = async (email: string | undefined) => {
    if (!email) {
      setIsWhitelisted(false);
      setIsLoading(false);
      return;
    }

    try {
      // Using RPC call instead of direct table access
      const { data, error } = await supabase.rpc('check_whitelist', {
        email_to_check: email.toLowerCase()
      });

      console.log('Whitelist check result:', { data, error });

      if (error) throw error;
      setIsWhitelisted(Boolean(data));
    } catch (error) {
      console.error('Error checking whitelist:', error);
      setIsWhitelisted(false);
    }
    setIsLoading(false);
  };

  const signIn = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email.toLowerCase(),
      password,
    });
    console.log('Sign in response:', { data, error });
    return { error };
  };

  const signUp = async (email: string, password: string) => {
    try {
      const normalizedEmail = email.toLowerCase();
      
      // Using RPC call to check whitelist
      const { data: isWhitelisted, error: whitelistError } = await supabase.rpc(
        'check_whitelist',
        { email_to_check: normalizedEmail }
      );

      console.log('Whitelist check for signup:', { isWhitelisted, whitelistError });

      if (whitelistError) {
        console.error('Whitelist check error:', whitelistError);
        return { 
          error: { message: 'Unable to verify signup authorization. Please try again later.' },
          data: null 
        };
      }

      if (!isWhitelisted) {
        return { 
          error: { message: 'No account found for this email. Please request beta access.' },
          data: null 
        };
      }

      // If whitelisted, proceed with signup
      const { data, error } = await supabase.auth.signUp({
        email: normalizedEmail,
        password,
        options: {
          emailRedirectTo: window.location.origin,
        }
      });

      console.log('Sign up response:', { data, error });

      if (error) throw error;

      return { data, error: null };
    } catch (error: any) {
      console.error('Sign up error:', error);
      return { error, data: null };
    }
  };

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        signIn,
        signUp,
        signOut,
        isWhitelisted,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 