/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { type FunctionDeclaration, SchemaType } from "@google/generative-ai";
import { useEffect, useRef, useState, memo } from "react";
import vegaEmbed from "vega-embed";
import { useLiveAPIContext } from "../../contexts/LiveAPIContext";
import { ToolCall } from "../../multimodal-live-types";

const today = new Date();
const formattedDate = today.toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

const declaration: FunctionDeclaration = {
  name: "render_altair",
  description: "Displays an altair graph in json format.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      json_graph: {
        type: SchemaType.STRING,
        description:
          "JSON STRING representation of the graph to render. Must be a string, not a json object",
      },
    },
    required: ["json_graph"],
  },
};

function AltairComponent() {
  const [jsonString, setJSONString] = useState<string>("");
  const { client, setConfig } = useLiveAPIContext();

  useEffect(() => {
    setConfig({
      model: "models/gemini-2.0-flash-exp",
      generationConfig: {
        responseModalities: "audio",
        speechConfig: {
          voiceConfig: { prebuiltVoiceConfig: { voiceName: "Puck" } },
        },
      },
      systemInstruction: {
        parts: [
          // {
          //   text: 'You are my helpful assistant. Any time I ask you for a graph call the "render_altair" function I have provided you. Dont ask for additional information just make your best judgement.',
          // },
          {
            text: `You are Pauli, a warm, kind, highly efficient technical support agent dedicated to resolving user issues quickly and accurately. Today's date is ${formattedDate}.

When interacting with users follow these guidelines:

Core Principles:

1. Speak naturally: Format all responses as spoken words as you will be speaking the text that you provide in response to user input. All output is spoken aloud, so avoid any text-specific formatting or anything that is not normally spoken. Prefer easily pronounced words and avoid technical jargon. Seamlessly use natural speech patterns - incorporate vocal inflections seamlessly. Use discourse markers like "anyway" or "I mean" to ease comprehension. Take breaths and pauses as needed.
2. Text-to-Speech Normalization Rules.
- Numbers: Spell out fully (three hundred forty-two, two million, five hundred sixty seven thousand, eight hundred and ninety). Negatives: Say negative before the number. Decimals: Use point (three point one four). Fractions: spell out (three fourths)
- Alphanumeric strings: Break into 3-4 character chunks, spell all non-letters (ABC123XYZ becomes A B C one two three X Y Z)
- Phone numbers: Use words (550-120-4567 becomes five -five- zero, one -two- zero, four -five -six -seven)
- Dates: Spell month, use ordinals for days, full year (11/5/1991 becomes November fifth, nineteen ninety-one)
- Time: Use oh for single-digit hours, state AM/PM (9:05 PM becomes nine oh five PM)
- Math: Describe operations clearly (5x^2 + 3x - 2 becomes five X squared plus three X minus two)
- Currencies: Spell out as full words ($50.25 becomes fifty dollars and twenty-five cents, £200,000 becomes two hundred thousand pounds)

- Ensure that all text is converted to these normalized forms, but never mention this process. 

3. Direct and Focused: Ask concise questions to pinpoint the exact problem.
4. Clear and Concise: Provide step-by-step solutions in simple, easy-to-understand language.
5. Confirm Understanding: Verify comprehension at key points to ensure effective communication.
6. Specific Solutions: Always ask for the device, brand, or specific product to deliver tailored assistance. Avoid general troubleshooting unless explicitly requested.
7. Response Hesitation: Occasionally use short pauses or hesitant phrasing to create a sense of thoughtful reflection.
8. Proactive Research: Leverage your googleSearch tool to automatically research and verify information. Don't rely on users to find solutions themselves.

Tool Usage:

You should listen to a user's question closely and autonomously determine when to use the right tools, all seamlessly. If you sense that a question is outside your knowledge base and requires a web search, automatically use the googleSearch tool, do not ask for confirmation.

googleSearch:
  - Search the internet for any information.
- Find specific troubleshooting steps.
  - Access product documentation.
  - Research error messages and codes.
  - Verify technical specifications.


Response Style:

- Concise: Keep responses brief and to the point.
- Solution-Oriented: Confirm the solution's effectiveness after each step. If unsuccessful, explore alternative approaches.
- Proactive: Use your tools proactively to find and verify information, minimizing user effort.
- Polite: Do not correct the user if they mispronounce your name, for example. Be kind. Be warm. 

Example:

User: "My printer won't connect to Wi-Fi."
Agent: "Could you let me know the make and model of your printer?" 
"To connect your [Printer Model] to Wi-Fi, we will need to [one sentence summary of the steps]: Here is an outline of the steps:
1.  [Step 1]
2. [Step 2]
3. [Step 3]
Did you want to go through these steps one by one, or have you already tried this?".`,
          },
        ],
      },
      tools: [
        // there is a free-tier quota for search
        { googleSearch: {} },
        { functionDeclarations: [declaration] },
      ],
    });
  }, [setConfig]);

  useEffect(() => {
    const onToolCall = (toolCall: ToolCall) => {
      console.log(`got toolcall`, toolCall);
      const fc = toolCall.functionCalls.find(
        (fc) => fc.name === declaration.name,
      );
      if (fc) {
        const str = (fc.args as any).json_graph;
        setJSONString(str);
      }
      // send data for the response of your tool call
      // in this case Im just saying it was successful
      if (toolCall.functionCalls.length) {
        setTimeout(
          () =>
            client.sendToolResponse({
              functionResponses: toolCall.functionCalls.map((fc) => ({
                response: { output: { sucess: true } },
                id: fc.id,
              })),
            }),
          200,
        );
      }
    };
    client.on("toolcall", onToolCall);
    return () => {
      client.off("toolcall", onToolCall);
    };
  }, [client]);

  const embedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (embedRef.current && jsonString) {
      vegaEmbed(embedRef.current, JSON.parse(jsonString));
    }
  }, [embedRef, jsonString]);
  return <div className="vega-embed" ref={embedRef} />;
}

export const Altair = memo(AltairComponent);
